var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && _vm.detailNews)?_c('b-container',{staticClass:"detail-page px-0 pb-0",attrs:{"fluid":""}},[_c('b-container',{staticClass:"banner"},[_c('img',{staticClass:"banner-img",attrs:{"src":_vm.detailNews.article_thumbnail,"alt":""}})]),_c('b-container',{staticClass:"detail-news-content"},[_c('div',{staticClass:"info-news-detail"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime(_vm.detailNews.created_at))+" ")]),_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(_vm.detailNews.article_blog_name)+" ")])]),_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.detailNews.article_title)+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.detailNews.article_description)}}),_c('div',{staticClass:"content-news"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.detailNews.article_content)}})])])]),(_vm.relatedNews && _vm.relatedNews.length > 0)?_c('b-container',{staticClass:"related-news",attrs:{"fluid":""}},[_c('b-container',{staticClass:"related-news-post"},[_c('div',{staticClass:"section-news"},[_c('div',{staticClass:"section-title"},[_vm._v("Related Post")]),_c('b-row',{staticClass:"list-news"},_vm._l((_vm.relatedNews.slice(0, 3)),function(news){return _c('b-col',{key:("box-news-" + (news.article_id)),attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"news-box"},[_c('router-link',{staticClass:"thumbnail",attrs:{"to":{
                                    name: 'NewsDetail',
                                    params: {
                                        slug: news.article_slug,
                                    },
                                }}},[_c('img',{attrs:{"src":news.article_thumbnail,"alt":""}})]),_c('div',{staticClass:"content-news"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime(news.created_at))+" ")]),_c('router-link',{attrs:{"to":{
                                            name: 'NewsDetail',
                                            params: {
                                                slug: news.article_slug,
                                            },
                                        }}},[_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(news.article_blog_name)+" ")])])],1),_c('router-link',{attrs:{"to":{
                                        name: 'NewsDetail',
                                        params: {
                                            slug: news.article_slug,
                                        },
                                    }}},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(news.article_title)+" ")])])],1)],1)])}),1)],1)])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }