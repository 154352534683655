<script>
const BlogAPI = require('@/store/axios2.config').default;

export default {
    data() {
        return {
            detailNews: {},
            relatedNews: [],
            type: '',
            show: true,
        };
    },
    methods: {
        async getNewsDetail() {
            const response = await BlogAPI.get('article/detail', {
                params: {
                    slug: this.$route.params.slug,
                },
            });
            this.detailNews = response;
        },
        async getRelatedNews() {
            const response = await BlogAPI.get('article/related', {
                params: {
                    slug: this.$route.params.slug,
                },
            });
            this.relatedNews = response;
        },
    },
    watch: {
        $route: {
            handler(newValue) {
                this.type = newValue.params.type;
                this.getNewsDetail();
                this.getRelatedNews();
            },
            deep: true,
        },
        detailNews: {
            handler() {
                this.show = false;
                this.$nextTick(() => {
                    this.show = true;
                });
            },
        },
        relatedNews: {
            handler() {
                this.show = false;
                this.$nextTick(() => {
                    this.show = true;
                });
            },
        },
    },
    created() {
        this.type = this.$route.params.type;

        this.getNewsDetail();
        this.getRelatedNews();
    },
};
</script>

<template>
    <b-container fluid class="detail-page px-0 pb-0" v-if="show && detailNews">
        <b-container class="banner">
            <img :src="detailNews.article_thumbnail" alt="" class="banner-img"/>
        </b-container>
        <b-container class="detail-news-content">
            <div class="info-news-detail">
                <div class="info-news">
                    <div class="date">
                        {{ getDateTime(detailNews.created_at) }}
                    </div>
                    <div class="type-news">
                        {{ detailNews.article_blog_name }}
                    </div>
                </div>

                <h1 class="title">
                    {{ detailNews.article_title }}
                </h1>

                <div
                    class="description"
                    v-html="detailNews.article_description"
                ></div>
                <div class="content-news">
                    <p v-html="detailNews.article_content"></p>
                </div>
            </div>
        </b-container>
        <b-container
            fluid
            class="related-news"
            v-if="relatedNews && relatedNews.length > 0"
        >
            <b-container class="related-news-post">
                <div class="section-news">
                    <div class="section-title">Related Post</div>
                    <b-row class="list-news">
                        <b-col
                            lg="4"
                            sm="6"
                            cols="12"
                            v-for="news in relatedNews.slice(0, 3)"
                            :key="`box-news-${news.article_id}`"
                        >
                            <div class="news-box">
                                <router-link
                                    :to="{
                                        name: 'NewsDetail',
                                        params: {
                                            slug: news.article_slug,
                                        },
                                    }"
                                    class="thumbnail"
                                >
                                    <img :src="news.article_thumbnail" alt="" />
                                </router-link>
                                <div class="content-news">
                                    <div class="info-news">
                                        <div class="date">
                                            {{ getDateTime(news.created_at) }}
                                        </div>
                                        <router-link
                                            :to="{
                                                name: 'NewsDetail',
                                                params: {
                                                    slug: news.article_slug,
                                                },
                                            }"
                                        >
                                            <div class="type-news">
                                                {{ news.article_blog_name }}
                                            </div>
                                        </router-link>
                                    </div>
                                    <router-link
                                        :to="{
                                            name: 'NewsDetail',
                                            params: {
                                                slug: news.article_slug,
                                            },
                                        }"
                                    >
                                        <h3 class="title">
                                            {{ news.article_title }}
                                        </h3>
                                    </router-link>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.detail-page {
    .detail-news-content {
        max-width: 992px;
        margin: auto;
        padding-bottom: 50px;
    }
    .banner {
        &.container {
            position: relative;
            min-height: calc(100vh - 100px);
            border-radius: 25px;
            @media (max-width: 575px) {
                max-width: calc(100% - 30px);
            }
            @media (min-width: 1200px) {
                max-width: 1200px;
            }
            @media (min-width: 1500px) {
                max-width: 1400px;
            }

            img.banner-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 25px;
            }
        }
    }
    .info-news-detail {
        margin-top: 20px;
        .info-news {
            display: flex;
            .date {
                margin-right: 16px;
                color: #16141a;
                font-size: 13px;
                font-weight: 600;
                text-decoration: none;
                text-transform: uppercase;
            }
            .type-news {
                color: #7d8499;
                font-size: 13px;
                text-decoration: none;
            }
        }
        h1.title {
            font-size: 50px;
            font-weight: 500;
            margin-top: 12px;
            line-height: 60px;
            margin-bottom: 0;
            color: #16141a;
            @media (max-width: 575px) {
                font-size: 35px;
                line-height: 45px;
            }
        }
        .description {
            font-size: 20px;
            line-height: 28px;
            margin-top: 8px;
            margin-bottom: 0;
            color: #16141a;
            font-weight: 400;
        }
        .content-news {
            height: 100%;
            position: relative;
            * {
                float: unset!important;
                width: 100%!important;
            }
            img {
                max-width: 100% !important;
            }
        }
    }
}
.related-news {
    background: rgba(204, 204, 204, 0.2);
    padding: 50px 0;
    .section-news {
        .list-news {
            justify-content: center;
            .news-box {
                a {
                    color: #16141a !important;
                }
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                text-decoration: none !important;
                margin: 30px auto;
                cursor: pointer;
                .thumbnail {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
                    img {
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        transition: transform 250ms ease, box-shadow 250ms ease;
                        border-radius: 10px;
                        height: 180px;
                    }
                }
                .content-news {
                    flex: 1 1 auto;
                    padding: 0 10px 5px;
                    .info-news {
                        display: flex;
                        margin: 15px 0 5px;
                        .date {
                            margin-right: 16px;
                            color: #16141a;
                            font-size: 13px;
                            font-weight: 600;
                            text-decoration: none;
                            text-transform: uppercase;
                        }
                        .type-news {
                            color: #7d8499;
                            font-size: 13px;
                            text-decoration: none;
                        }
                    }
                    h3.title {
                        color: #16141a;
                        text-decoration: none;
                        margin: 0;
                        font-size: 22px;
                        line-height: 28px;
                        font-weight: 500;
                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                    .description {
                        display: block;
                        font-size: 17px;
                        line-height: 26px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        padding-top: 8px;
                        color: #16141a;
                    }
                }
                @media (max-width: 575px) {
                    .thumbnail {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 40.25%;
                        position: relative;
                        border-radius: 10px;
                        max-height: 300px;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            border-radius: 10px;
                            object-fit: cover;
                            max-height: 300px;
                        }
                    }
                }
                @media (max-width: 425px) {
                    .thumbnail {
                        padding-top: 56.25%;
                    }
                }
            }
        }
        .button-load {
            background: linear-gradient(90deg, #00ffa9, #00b1ff);
            width: 200px;
            height: 50px;
            padding: 0;
            color: #000;
            font-family: Helvetica;
            font-size: 1.1em;
            font-weight: 900;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px auto;
        }
        .section-title {
            color: #000;
            font-weight: 500;
            font-size: 1.4em;
            width: 100%;
            border-bottom: 3px solid rgba(255, 255, 255, 0.5);
        }
    }
}
</style>
